<template>
  <div id="header-tab-flight-id">
    <swiper
      class="swiper"
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="(trip, tripIndex) in getSearchFlightArray"
        :key="tripIndex"
        class="slide fit-content"
      >
        <BButton
          variant="vna-color"
          class="p-75 mb-25 rounded-8px text-left d-flex-center align-items-start flex-column"
          :style="tabIndex === tripIndex ? 'background: #166987; color: white' : 'color: #166987; background: white; border: 1px solid #166987;'"
          @click="handleClickTab(tripIndex)"
        >
          <span class="fw-700 text-16px">
            #{{ tripIndex + 1 }}
            {{ `${trip.startPoint}-${trip.endPoint} ${convertISODateTime(trip.departDate).date}` }}
          </span>
          <span class="text-14px font-weight-light">
            <span v-if="selectedTrips[tripIndex]">
              {{ `${resolveAirlineFlightNumber(selectedTrips[tripIndex].airline, selectedTrips[tripIndex].flightNumber)}` }}
              {{ convertISODateTime(selectedTrips[tripIndex].departure.at, selectedTrips[tripIndex].departure.timezone).time }} - {{ convertISODateTime(selectedTrips[tripIndex].arrival.at, selectedTrips[tripIndex].arrival.timezone).time }}
            </span>
            <span
              v-else
              class="text-warning"
            >
              {{ $t('flight.notSelectedFlights') }}
            </span>
          </span>
        </BButton>
      </swiper-slide>

      <!-- <swiper-slide
        v-if="getGroupedItineraryResponse"
        class="slide fit-content"
      >
        <BButton
          variant="vna-color"
          class="p-75 mb-25 rounded-8px text-left d-flex-center align-items-start flex-column"
          :style="tabIndex === getSearchFlightArray.length ? 'background: #166987; color: white' : 'color: #166987; background: white; border: 1px solid #166987;'"
          @click="handleClickTab(getSearchFlightArray.length)"
        >
          <div class="d-flex align-items-center gap-1">
            <span class="fw-700 text-16px text-warning d-flex-center flex-column">
              <div>{{ headerSource(flightSearch).firstLabel }}</div>
              <div>{{ headerSource(flightSearch).bottomLabel }}</div>
            </span>
            <div class="d-flex-center flex-column gap-1">
              <span
                v-for="(trip, tripIndex) in getSearchFlightArray"
                :key="tripIndex"
              >{{ `${trip.startPoint}-${trip.endPoint} ${convertISODateTime(trip.departDate).date}` }}</span>
            </div>
          </div>
        </BButton>
      </swiper-slide> -->
    </swiper>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { computed } from '@vue/composition-api'

import store from '@/store'

import { convertISODateTime, resolveAirlineFlightNumber } from '@core/utils/filter'

import useReservationDetailBookingHandle from '@reservation/reservation-modify/components/detail/flights-details/useReservationDetailBookingHandle'

export default {
  components: {
    BButton,
    Swiper,
    SwiperSlide,
  },
  computed: {
    tabIndex: {
      get() { return this.$store.getters['app-reservation/getTabIndex'] },
      set(newValue) { this.$store.dispatch('app-reservation/setTabIndex', newValue) },
    },
  },
  setup() {
    const swiperOptions = computed(() => ({
      freeMode: true,
      spaceBetween: 5,
    }))

    const {
      selectedTrips,
      getSearchFlightArray,
      flightSearch,
    } = useReservationDetailBookingHandle()

    // const getGroupedItineraryResponse = computed(() => store.getters['app-flight-v2/getGroupedItineraryResponse'])

    // const headerSource = data => {
    //   const result = {
    //     firstLabel: 'GKH',
    //     bottomLabel: '1G NDC',
    //   }
    //   if (!data) return result
    //   if (!isEmpty(data.sources)) {
    //     if (data.sources.includes('1A')) {
    //       if (['1G', '1A'].every(s => data.sources.includes(s))) {
    //         result.firstLabel = '1A GHK'
    //       } else {
    //         result.bottomLabel = '1A NDC'
    //       }
    //     }
    //   }
    //   return result
    // }

    function handleClickTab(index) {
      store.dispatch('app-reservation/setTabIndex', index)
    }
    return {
      swiperOptions,
      convertISODateTime,
      handleClickTab,
      flightSearch,
      getSearchFlightArray,
      selectedTrips,
      // headerSource,
      // getGroupedItineraryResponse,
      resolveAirlineFlightNumber,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';

#header-tab-flight-id {
  position: sticky;
  top: 50px;
  z-index: 10;
}
</style>
